var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0 py-3 mt-3 page-title"},[_c('h3',{staticClass:"card-title align-items-center"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.$t("MENU.CURRENCY_LIST")))])])]),_c('div',{staticClass:"card-body pb-0"},[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([(_vm.items.length === 0)?{key:"bottom-row",fn:function(items){return [_c('b-td',{staticClass:"text-center",attrs:{"colspan":_vm.fields.length}},[_vm._v(_vm._s(_vm.$t("TABLE.NO_DATA")))])]}}:null,{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(status_deposit)",fn:function(data){return [(_vm.getAccess.includes('currency--UPDATE'))?_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":data.item.status_deposit ? 'success' : 'danger'},on:{"click":function($event){return _vm.updateCurrency(
                data.item.code,
                'DEPOSIT',
                data.item.status_deposit
              )}}},[_vm._v(_vm._s(data.item.status_deposit ? _vm.$t("TABLE.ACTIVE") : _vm.$t("TABLE.INACTIVE")))]):_vm._e()]}},{key:"cell(status_withdrawal)",fn:function(data){return [(_vm.getAccess.includes('currency--UPDATE'))?_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":data.item.status_withdrawal ? 'success' : 'danger'},on:{"click":function($event){return _vm.updateCurrency(
                data.item.code,
                'WITHDRAWAL',
                data.item.status_withdrawal
              )}}},[_vm._v(_vm._s(data.item.status_withdrawal ? _vm.$t("TABLE.ACTIVE") : _vm.$t("TABLE.INACTIVE")))]):_vm._e()]}}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }