<template>
  <div>
    <!-- Start Content  -->

    <!-- Start List  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-3 mt-3 page-title">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("MENU.CURRENCY_LIST")
          }}</span>
        </h3>
      </div>
      <div class="card-body pb-0">
        <b-table class="bg-white" striped hover :fields="fields" :items="items">
          <!-- Set No Data when none of item -->
          <template #bottom-row="items" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>

          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(status_deposit)="data">
            <b-button
              v-if="getAccess.includes('currency--UPDATE')"
              size="sm"
              class="mr-3"
              :variant="data.item.status_deposit ? 'success' : 'danger'"
              @click="
                updateCurrency(
                  data.item.code,
                  'DEPOSIT',
                  data.item.status_deposit
                )
              "
              >{{
                data.item.status_deposit
                  ? $t("TABLE.ACTIVE")
                  : $t("TABLE.INACTIVE")
              }}</b-button
            >
          </template>

          <template #cell(status_withdrawal)="data">
            <b-button
              v-if="getAccess.includes('currency--UPDATE')"
              size="sm"
              class="mr-3"
              :variant="data.item.status_withdrawal ? 'success' : 'danger'"
              @click="
                updateCurrency(
                  data.item.code,
                  'WITHDRAWAL',
                  data.item.status_withdrawal
                )
              "
              >{{
                data.item.status_withdrawal
                  ? $t("TABLE.ACTIVE")
                  : $t("TABLE.INACTIVE")
              }}</b-button
            >
          </template>
        </b-table>
      </div>
    </div>
    <!-- End List  -->
    <!-- End Content  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import {
  GET_CURRENCY,
  UPDATE_CURRENCY
} from "@/core/services/store/currency.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  computed: {
    ...mapGetters(["currentTier", "getAccess"])
  },
  mixins: [globalMixin],
  data() {
    return {
      fields: [
        {
          key: "index",
          label: this.$t("TABLE.INDEX"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "code",
          label: this.$t("TABLE.NAME"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "status_deposit",
          label: this.$t("MENU.DEPOSIT"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "status_withdrawal",
          label: this.$t("MENU.WITHDRAWAL"),
          tdClass: "align-middle action-column",
          thClass: "text-center"
        }
      ],
      items: []
    };
  },
  mounted() {
    this.getCurrency();
  },
  methods: {
    getCurrency() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_CURRENCY)
        .then((e) => {
          this.items = e;
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    updateCurrency(code, type, status) {
      Swal.fire({
        title: `${
          status
            ? this.$t("CURRENCY.CONFIRMATION_DISABLE")
            : this.$t("CURRENCY.CONFIRMATION_ENABLE")
        } "${code}" ?`,
        showCancelButton: true,
        confirmButtonText: this.$t("MODAL.OK"),
        cancelButtonText: this.$t("MODAL.CANCEL")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          this.$store
            .dispatch(UPDATE_CURRENCY, { code, type })
            .then(() => {
              this.getCurrency();
              Swal.fire({
                text: this.$t("MODAL.UPDATE_SUCESSFULL"),
                type: "success",
                confirmButtonText: this.$t("MODAL.OK")
              });
            })
            .catch((err) => {
              Swal.fire({
                text: this.translateErrorFromCode(err),
                type: "error",
                confirmButtonText: this.$t("MODAL.OK")
              });
            })
            .then(() => {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.page-title {
  min-height: auto !important;
}

::v-deep .action-column {
  min-width: 175px;
  text-align: center;
}

::v-deep .amount-column {
  width: 50px;
}
</style>
